import { BulkUploadRow } from "../type";

export const UPLOAD_TEMPLATE = "UPLOAD_TEMPLATE";
export const DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE";

export const CHANGE_HISTORY_TEMPLATE = "CHANGE_HISTORY_TEMPLATE";

export const GET_BULK_UPLOAD_DETAILS = "GET_BULK_UPLOAD_DETAILS";
export const SET_BULK_UPLOAD_DETAILS = "SET_BULK_UPLOAD_DETAILS";

export type UploadTemplate = {
  type: typeof UPLOAD_TEMPLATE;
  payload: File;
};

export type DownloadTemplate = {
  type: typeof DOWNLOAD_TEMPLATE;
  payload: string;
};

export type DownloadHistoryTemplate = {
  type: typeof CHANGE_HISTORY_TEMPLATE;
  payload: string;
};

export type GetBulkUploadDetails = {
  type: typeof GET_BULK_UPLOAD_DETAILS;
};

export type SetBulkUploadDetails = {
  type: typeof SET_BULK_UPLOAD_DETAILS;
  payload: Array<BulkUploadRow>;
};

export const uploadTemplateAction = (file: File) => {
  return {
    type: UPLOAD_TEMPLATE,
    payload: file,
  };
};

export const downloadTemplateAction = (dataType: string) => {
  return {
    type: DOWNLOAD_TEMPLATE,
    payload: dataType,
  };
};

export const downloadHistoryTemplateAction = (dataType: string) => {
  return {
    type: CHANGE_HISTORY_TEMPLATE,
    payload: dataType,
  };
}

export const getBulkUploadDetailsAction = () => {
  return {
    type: GET_BULK_UPLOAD_DETAILS,
  };
};

export const setBulkUploadDetailsAction = (data: Array<BulkUploadRow>) => {
  return {
    type: SET_BULK_UPLOAD_DETAILS,
    payload: data,
  };
};

export type BulkUploadActions =
  | UploadTemplate
  | SetBulkUploadDetails
  | GetBulkUploadDetails
  | DownloadTemplate
  | DownloadHistoryTemplate;

