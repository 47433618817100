import _ from "../../Utils/lodash";
import { HIDE_ERROR_TABLE, SHOW_ERROR_TABLE } from "../actions/error_table_actions";
import { IActionParams, IErrorTableProps } from "../type";

export const errorTableInitialState: IErrorTableProps = {
	open: false,
	data: []
};

export const errorTableReducer = (state = errorTableInitialState, action: IActionParams = {}) : IErrorTableProps => {
	switch (action.type) {
		case SHOW_ERROR_TABLE:
			return _.extend({}, state, action.payload);
		case HIDE_ERROR_TABLE:
			return _.extend({}, state, errorTableInitialState);
		default:
			return state;
	}
}
