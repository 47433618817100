import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  progressHelper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    backgroundColor: 'rgba(255, 255, 255, .5)'
  }
});
