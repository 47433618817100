import { takeEvery, call, put } from "redux-saga/effects";
import { showToaster } from "../actions/toaster_actions";
import { ToastTypes } from "../../Constants/enums";
import {
  CHANGE_HISTORY_TEMPLATE,
  DOWNLOAD_TEMPLATE,
  DownloadTemplate,
  GET_BULK_UPLOAD_DETAILS,
  getBulkUploadDetailsAction,
  setBulkUploadDetailsAction,
  UPLOAD_TEMPLATE,
  UploadTemplate,
  type DownloadHistoryTemplate,
} from "../actions/bulk_upload_actions";
import {
  downloadHistoryTemplateApi,
  downloadTemplateApi,
  uploadTemplateApi,
  uploadTrackerApi,
} from "../../Api/GenericApiCalls";
import fileSaver from "file-saver";
import { showLoader } from "../actions/loader_actions";
import { getExcelName } from "../../Utils/dateFormat";
import { SagaIterator } from "redux-saga";
import i18next from "i18next";
import type { IUploadResponseBody } from "../../Constants/interfaces";
import { showErrorTable } from "../actions/error_table_actions";

export function* downloadTemplateSaga({
  payload,
}: DownloadTemplate): SagaIterator<void> {
  try {
    yield put(showLoader(true));
    const response = yield call(downloadTemplateApi, payload);
    if (response?.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileSaver.saveAs(blob, getExcelName(payload));
    } else {
      yield put(
        showToaster({
          text: i18next?.t("dashboard.error_occurred_message"),
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("dashboard.error_occurred_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  } finally {
    yield put(showLoader(false));
  }
}

export function* downloadHistoryTemplateSaga({
  payload,
}: DownloadHistoryTemplate): SagaIterator<void> {
  try {
    yield put(showLoader(true));
    const response = yield call(downloadHistoryTemplateApi, payload);
    if (response?.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileSaver.saveAs(blob, '[history] ' + getExcelName(payload));
    } else {
      yield put(
        showToaster({
          text: i18next?.t("dashboard.error_occurred_message"),
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("dashboard.error_occurred_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  } finally {
    yield put(showLoader(false));
  }
}

export function* getUploadDeatilsSaga(): SagaIterator<void> {
  try {
    const response = yield call(uploadTrackerApi);
    if (response?.status === 200) {
      yield put(setBulkUploadDetailsAction(response.data));
    } else {
      yield put(
        showToaster({
          text: i18next?.t("dashboard.error_occurred_message"),
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("dashboard.error_occurred_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* uploadTemplateSaga({
  payload,
}: UploadTemplate): SagaIterator<void> {
  try {
    const response = yield call(uploadTemplateApi, payload);
    if (response?.status === 200) {
      const { successCount, totalRecords, rows } = response.data as IUploadResponseBody;
      const isError = rows.some(i=>i.status == "FAILED")
      if (!isError)
        yield put(
          showToaster({
            text: `${successCount} out of ${totalRecords} records uploaded successfully`,
            type: ToastTypes.Success,
            time: 5000,
          })
        );
      else yield put(showErrorTable(rows))
      yield put(getBulkUploadDetailsAction());
    } else {
      yield put(
        showToaster({
          text: response?.data?.message || i18next?.t("dashboard.error_occurred_message"),
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("dashboard.error_occurred_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* watchBulkUpload() {
  yield takeEvery(DOWNLOAD_TEMPLATE, downloadTemplateSaga);
  yield takeEvery(CHANGE_HISTORY_TEMPLATE, downloadHistoryTemplateSaga)
  yield takeEvery(UPLOAD_TEMPLATE, uploadTemplateSaga);
  yield takeEvery(GET_BULK_UPLOAD_DETAILS, getUploadDeatilsSaga);
}
