import { VerifyOtpPayload } from '../redux/type'
import { IRequestBody } from '../Constants/interfaces'
import { apiService } from './ApiService'
import apiEndpoints, { baseUrl } from './EndPoints'
/**
 * @description function triggers an api call to get roles
 * and base_url
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */

/** verify email and send otp api */
export const sendOtpEmailApi = (email: string) => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = `${apiEndpoints.sendOtpEmailUrl}?id=${email}`
    apiData.showLoader = true
    return apiService.get(apiData)
}

/** verify email and send otp api */
export const verifyOtpApi = ({ email, otp }: VerifyOtpPayload) => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = apiEndpoints.verifyOtpUrl
    apiData.showLoader = true
    apiData.payLoad = {
        recipientId: email,
        channelType: 'EMAIL_ID',
        otp,
    }
    return apiService.post(apiData)
}

/** upload master data template */
export const uploadTemplateApi = (file: File) => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = apiEndpoints.uploadTemplateUrl
    apiData.showLoader = true
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)
    apiData.payLoad = formData
    apiData.headerType = 'multipart/form-data'
    return apiService.uploadFile(apiData)
}

/** download template by data type */
export const downloadTemplateApi = (dataType: string) => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = `${apiEndpoints.downloadTemplateUrl}?category=${dataType}`
    apiData.showLoader = true
    apiData.responseType = 'arraybuffer' as never
    return apiService.get(apiData)
}

export const downloadHistoryTemplateApi = (dataType: string) => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = `${apiEndpoints.downloadHistoryUrl}?category=${dataType}`
    apiData.showLoader = true
    apiData.responseType = 'arraybuffer' as never
    return apiService.get(apiData)
}

/** get last upload details*/
export const uploadTrackerApi = () => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = apiEndpoints.uploadTrackerUrl
    apiData.showLoader = true
    return apiService.get(apiData)
}

/** get logout call*/
export const logoutApi = () => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = apiEndpoints.logout
    apiData.showLoader = true
    return apiService.post(apiData)
}

/** get logout from all device call*/
export const logoutFromAllDeviceApi = () => {
    const apiData = {} as IRequestBody
    apiData.domain = baseUrl
    apiData.endPoint = apiEndpoints.logoutAll
    apiData.showLoader = true
    return apiService.post(apiData)
}
