export enum RequestMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

export enum SORT_OPTIONS {
  'asc' = 'ASC',
  'dsc' = 'DESC'
}

export enum ToastTypes {
  'Error' = 'error',
  'Success' = 'success',
  'Info' = 'info',
  'Warn' = 'warn',
}

export enum ErrorStatus {
  NOT_FOUND = "NOT_FOUND",
  EMPTY_VALUE = "EMPTY_VALUE",
  DUPLICATE = "DUPLICATE",
  BLANK = "BLANK",
  ENUM_FORMAT_ERROR = "ENUM",
  INVALID_EMAIL_FORMAT = "INVALID_EMAIL_FORMAT",
  WRONG_FORMAT = "WRONG_FORMAT",
  REQUIRED = "REQUIRED",
  EXTEND_ERROR = "EXTEND_ERROR"
}
