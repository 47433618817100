import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  containerHelper: {
    display: 'flex',
    width: '100%',
height:'calc(100vh - 84px)',
    position: 'absolute',
    overflowY: 'auto',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '8px',
      height:'8px'
    },
    '*::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px',

    },
    '*::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
  },
});
