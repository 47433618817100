import type { IErrorTableProps } from "../type";

export const HIDE_ERROR_TABLE = "HIDE_ERROR_TABLE";
export const SHOW_ERROR_TABLE = "SHOW_ERROR_TABLE";

export type ShowErrorTable = {
	type: typeof SHOW_ERROR_TABLE;
	payland: IErrorTableProps;
};

export type HideErrorTable = {
	type: typeof HIDE_ERROR_TABLE;
};

export const showErrorTable = (data: IErrorTableProps["data"]) => {
	return {
		type: SHOW_ERROR_TABLE,
		payload: {
			open: true,
			data
		}
	};
};

export const hideErrorTable = () => {
	return {
		type: HIDE_ERROR_TABLE,
		payload: {
			open: false,
			data: []
		}
	};
};

export type ErrorTableActions = ShowErrorTable | HideErrorTable
